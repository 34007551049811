import { Component, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AsyncMergeJobInspectionDetailDto,
  BuildingItemDto,
  EmailMergeDto,
  InspectionDetailDto,
  InspectionEntryMergeDto,
  InspectionMergeDto,
  InspectionPdfItemDto,
  InspectionPdfMergeDto,
  InspectionService,
  UserDetailDto,
  UserService,
} from '@artemis-software/wr-api';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  repeat,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { TimeUtils } from '@shared/form-controls/time-picker/time-picker.component';
import { NbDialogService, NbIconConfig, NbTabComponent, NbToastrService } from '@nebular/theme';
import { ConfirmDialogComponent } from '@components/dialog/confirm-dialog/confirm-dialog.component';
import {
  PreviousInspectionDialogComponent,
} from '@components/dialog/previous-inspection-dialog/previous-inspection-dialog.component';
import { InspectionFormWrapper } from './inspection-form';
import { createInspectionEntryForm, createInspectionForm, InspectionFormType } from './inspection-util';
import { AuthService } from '@services/auth.service';
import { InspectionEntriesComponent } from './inspection-entries/inspection-entries.component';
import { DetailFormComponent } from './detail-form/detail-form.component';
import { ConfirmEditDialogComponent } from '@components/dialog/confirm-edit-dialog/confirm-edit-dialog.component';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { tryWithLoading } from '@/utils/async-utils';
import { Select, Store } from '@ngxs/store';
import { LoadAddresses } from '@/store/address/address.actions';
import { RepairTasksComponent } from '@pages/inspection-detail-page-v2/repair-tasks/repair-tasks.component';
import { isAdmin } from '@/utils/admin-utils';
import {
  building_equipments_tab,
  entries_tab,
  general_tab,
  meta_data_tab,
  pdfs_tab,
  repair_tasks_tab,
} from '@pages/inspection-detail-page-v2/inspection-tab.constants';
import { Location } from '@angular/common';
import { SendEmailComponent } from '@components/dialog/send-email/send-email.component';
import { joinUrl } from '@/utils/url-utils';
import { InspectionPdfState } from '@/store/inspectionPdf/inspectionPdf.state';
import { FilterInspectionPdfs } from '@/store/inspectionPdf/inspectionPdf.action';
import { InfoDialogComponent } from '@components/dialog/info-dialog/info-dialog.component';
import InspectionPdfTypeEnum = InspectionPdfMergeDto.InspectionPdfTypeEnum;

const autoSaveDuration = 2 * 60 * 1000;
const saveIcon: NbIconConfig = { icon: 'save-outline', pack: 'eva' };

@Component({
  selector: 'wr-inspection-detail-page-v2',
  templateUrl: './inspection-detail-page-v2.component.html',
  styleUrls: ['./inspection-detail-page-v2.component.scss'],
})
export class InspectionDetailPageV2Component implements PendingChanges, OnDestroy, OnInit {

  @ViewChild(InspectionEntriesComponent)
  inspectionEntriesComponent?: InspectionEntriesComponent;
  @ViewChild(DetailFormComponent)
  detailFormComponent?: DetailFormComponent;
  @ViewChild(RepairTasksComponent)
  repairTasksComponent?: RepairTasksComponent;
  @Select(InspectionPdfState.filteredInspectionPdfs)
  inspectionPdfs$!: Observable<InspectionPdfItemDto[]>;
  isAdmin = isAdmin();
  isNew = false;
  lockEdit = false;
  formWrapper: InspectionFormWrapper = new InspectionFormWrapper();
  inspection?: InspectionDetailDto;
  firstSubmit = false;
  destroy$ = new Subject<void>();
  saving$ = new BehaviorSubject(false);
  importing$ = new BehaviorSubject(false);
  fetching$ = new BehaviorSubject(false);
  deleting$ = new BehaviorSubject(false);
  loading$ = combineLatest([
    this.saving$,
    this.importing$,
    this.fetching$,
    this.deleting$,
  ]).pipe(map((booleans) => booleans.some((b) => b)));
  currentUser$ = new BehaviorSubject<UserDetailDto | undefined>(undefined);
  inspectionEntryId: WritableSignal<string> = signal('');
  protected readonly general_tab = general_tab;
  protected readonly entries_tab = entries_tab;
  protected readonly building_equipments_tab = building_equipments_tab;
  protected readonly pdfs_tab = pdfs_tab;
  protected readonly repair_tasks_tab = repair_tasks_tab;
  protected readonly meta_data_tab = meta_data_tab;

  constructor(
    route: ActivatedRoute,
    private readonly router: Router,
    private readonly inspectionService: InspectionService,
    private readonly nbToastrService: NbToastrService,
    private readonly nbDialogService: NbDialogService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly location: Location,
  ) {
    route.paramMap.subscribe(async (params) => {
      const id = params.get('id');
      this.isNew = id === 'new';
      if (!id) {
        await this.router.navigate(['inspections']);
      } else {
        if (this.isNew) {
          await this.initForm(undefined);
          this.formWrapper.enable();
        } else {
          await tryWithLoading(this.fetching$, async () => {
            this.inspection = await firstValueFrom(this.inspectionService.findById(id));
            await this.initForm(this.inspection);
            this.store.dispatch(new FilterInspectionPdfs({ inspectionId: this.inspection?.id }));
          });
        }
      }
    });
    route.queryParamMap.subscribe((params) => {
      const inspectionEntryId = params.get('inspectionEntryId');
      this.inspectionEntryId.set(inspectionEntryId ?? '');
    });
  }

  get hasSendingStatus(): boolean {
    return this.inspection?.status == InspectionDetailDto.StatusEnum.Sent;
  }

  get willApplyPreviousInspection(): boolean {
    return !!this.formWrapper.form?.controls.applyPreviousInspection?.value;
  }

  get hasAnyPendingAttachments(): boolean {
    const detailPending = this.detailFormComponent?.hasAnyPendingAttachments ?? false;
    const entriesPending = this.inspectionEntriesComponent?.hasAnyPendingAttachments ?? false;
    return detailPending || entriesPending;
  }

  async initForm(inspection?: InspectionDetailDto): Promise<void> {
    this.lockEdit = inspection?.status === InspectionDetailDto.StatusEnum.InProgress;

    this.store.dispatch(new LoadAddresses(inspection?.building.sections.map(s => s.addressId) ?? []));

    const form = createInspectionForm(inspection);

    form.controls.building?.valueChanges.subscribe(async (building: BuildingItemDto | null | undefined) => {
      if (!inspection?.id && building) {
        const lastInspections = await firstValueFrom(this.inspectionService.findLastInspectionByBuilding({
          buildingId: building.id,
          searchText: '',
        }));
        if (lastInspections.length > 0) {
          const lastInspection = lastInspections[0];
          form.controls.continuationNumber?.setValue(lastInspection.continuationNumber + 1);
          form.controls.normType?.setValue(lastInspection.normType);
          const takeLastInspection = await firstValueFrom(this.nbDialogService.open(PreviousInspectionDialogComponent, {
            context: {
              inspection: lastInspection,
            },
          }).onClose);
          if (takeLastInspection) {
            this.clearEntries();
            form.controls.applyPreviousInspection?.setValue(lastInspection.id);
            form.controls.usedTemplateId.setValue(lastInspection.usedTemplateId);
          }else{
            form.controls.applyPreviousInspection?.setValue(undefined);
          }
        } else {
          form.controls.continuationNumber?.setValue(undefined);
          form.controls.normType?.setValue(undefined);
          form.controls.applyPreviousInspection?.setValue(undefined);
          this.nbToastrService.warning('Zu diesem Gebäude wurde kein vorheriger Rundgang gefunden!', 'Kein alter Rundgang gefunden.');
        }
      }
    });

    form.controls.status?.valueChanges.subscribe((status: InspectionDetailDto.StatusEnum) => {
      if (status === InspectionDetailDto.StatusEnum.Sent) {
        form.controls.sendingDate?.setValue(new Date());
      }
    });

    if (inspection) {
      this.createInspectionEntries(form, inspection);
    }

    this.formWrapper.form = form;
    this.formWrapper.disable();
  }

  createInspectionEntries(form: InspectionFormType, inspection: InspectionDetailDto) {
    const newInspectionEntries = inspection?.inspectionEntries?.map((e) => createInspectionEntryForm(e.checkpoint, {
      inspectionEntry: e,
    }));
    const formArray = form.controls?.inspectionEntries;

    if (!formArray) {
      throw new Error('Inspection entries not found');
    }

    if (newInspectionEntries) {
      newInspectionEntries.forEach((inspectionEntry) => {
        formArray.push(inspectionEntry);
      });
    }
  }

  clearEntries(): void {
    const formArray = this.formWrapper.controls?.inspectionEntries;

    if (!formArray) {
      throw new Error('Inspection entries not found');
    }

    while (formArray.length > 0) {
      formArray.removeAt(0);
    }
  }

  async hasPendingChanges(): Promise<boolean> {
    return this.formWrapper.enabled && this.formWrapper.dirty;
  }

  async saveInspection(autoSave: boolean): Promise<void> {
    if (this.hasAnyPendingAttachments) {
      this.nbToastrService.warning('Bilder werden noch hochgeladen. Bitte warten Sie einen Moment.', 'Bitte warten');
      return;
    }

    this.firstSubmit = true;
    this.formWrapper.markAllAsTouched();

    if (!this.formWrapper.valid) {
      this.nbToastrService.danger('Rundgang kann nicht gespeichert werden, es gibt noch Fehler.', 'Rundgang');
      return;
    }

    await tryWithLoading(this.saving$, async () => {
      const formValue = await this.formWrapper.getMappedValue();
      const buildingSectionImages = await Promise.all(
        this.formWrapper.form!.controls.buildingSectionImages.controls.map(section => section.getMappedValue()),
      );

      const entries = await Promise.all(this.formWrapper.controls!.inspectionEntries.controls.map(e => e.getMappedValue()));

      const inspectionDto: InspectionMergeDto = {
        ...formValue,
        buildingSectionImages,
        fromTimestamp: TimeUtils.atTime(formValue.date, formValue.fromTime)?.toISOString(),
        toTimestamp: TimeUtils.atTime(formValue.date, formValue.toTime)?.toISOString(),
        sendingTimestamp: formValue.sendingDate?.toISOString(),
        inspectionEntries: entries as InspectionEntryMergeDto[],
      };

      this.inspection = await this.mergeInspectionAndWaitForJobToComplete(inspectionDto);

      if (autoSave) {
        this.nbToastrService.info('Rundgang wurde automatisch gespeichert.', 'Gespeichert', { icon: saveIcon });
        this.formWrapper.controls?.version?.setValue(this.inspection.version);
        this.formWrapper.markAsPristine();
      } else {
        this.nbToastrService.success('Rundgang wurde gespeichert.', 'Gespeichert', { icon: saveIcon });
        await this.initForm(this.inspection);
        await this.router.navigate(['inspection', this.inspection.id, 'general']);
      }
    });
  }

  async cancel(): Promise<void> {
    if (!this.formWrapper.dirty || await firstValueFrom(this.nbDialogService.open(ConfirmDialogComponent).onClose)) {
      await this.initForm(this.inspection);
      this.formWrapper.disable();
    }
  }

  async navigateBack(): Promise<void> {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      await this.router.navigate(['inspections']);
    }
  }

  ngOnInit(): void {
    this.authService.loggedIn$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.userService.findCurrentUser()),
    ).subscribe((currentUser) => {
      this.currentUser$.next(currentUser);
    });

    forkJoin([
      timer(autoSaveDuration),
      defer(() => this.checkAutosave()),
    ]).pipe(
      repeat(),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  autoSaveEnabled(): boolean {
    return this.currentUser$.value?.autoSave ?? false;
  }

  async toggleAutosave(autoSaveEnabled: boolean): Promise<void> {
    const updatedUser = await firstValueFrom(this.userService.merge({
      id: this.currentUser$.value?.id,
      autoSave: autoSaveEnabled,
    }));
    this.currentUser$.next(updatedUser);
  }

  changeTab(tab: NbTabComponent) {
    const id = this.inspection?.id ?? 'new';
    if (tab.tabId !== entries_tab) {
      this.location.replaceState(`/inspection/${id}/${tab.tabId}`);
    } else {
      this.location.replaceState(`/inspection/${id}/${tab.tabId}`, this.inspectionEntryId());
    }
  }

  async showEditOverrideDialog(): Promise<void> {
    if (await firstValueFrom(this.nbDialogService.open(ConfirmEditDialogComponent).onClose)) {
      this.lockEdit = false;
    }
  }

  async showDeleteDialog(): Promise<void> {
    if (await firstValueFrom(this.nbDialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Rundgang löschen',
        message: 'Bist du sicher, dass du den Rundgang löschen willst?',
      },
    }).onClose)) {
      await tryWithLoading(this.deleting$, async () => {
        const id = this.formWrapper?.controls?.id?.value;
        if (!id) {
          throw new Error('Inspection id not found');
        }
        await firstValueFrom(this.inspectionService.deleteById(id));
        this.nbToastrService.success('Rundgang wurde erfolgreich gelöscht', 'Gelöscht');
        await this.router.navigate(['inspections']);
      });
    }
  }

  reloadRepairTasks() {
    this.repairTasksComponent?.reload();
  }

  async showEmailDialog(inspection: InspectionDetailDto) {

    let shortList: InspectionPdfItemDto | undefined;

    this.inspectionPdfs$.pipe(
      map(inspectionPdfs =>
        inspectionPdfs.find(element => element.inspectionPdfType === InspectionPdfTypeEnum.Shortlist),
      ),
    ).subscribe(result => shortList = result);

    let longList: InspectionPdfItemDto | undefined;

    this.inspectionPdfs$.pipe(
      map(inspectionPdfs =>
        inspectionPdfs.find(element => element.inspectionPdfType === InspectionPdfTypeEnum.Longlist),
      ),
    ).subscribe(result => longList = result);

    let certificateSheet: InspectionPdfItemDto | undefined;

    this.inspectionPdfs$.pipe(
      map(inspectionPdfs =>
        inspectionPdfs.find(element => element.inspectionPdfType === InspectionPdfTypeEnum.CertificateSheet),
      ),
    ).subscribe(result => certificateSheet = result);

    if (longList === undefined || shortList === undefined || certificateSheet === undefined) {
      const missingItems = [];

      if (shortList === undefined) {
        missingItems.push('Shortlist');
      }

      if (longList === undefined) {
        missingItems.push('Longlist');
      }

      if (certificateSheet === undefined) {
        missingItems.push('Zertifikat');
      }

      const missingItemsText = missingItems.join(' und ');

      let text = '';

      if (missingItems.length === 1) {
        text = `Es wurde noch keine ${missingItemsText} generiert!`;
      } else {
        text = `Es wurden noch keine ${missingItemsText} generiert!`;
      }

      this.nbDialogService.open(InfoDialogComponent, {
        context: {
          header: 'Fehlende Einträge',
          text: text,
        },
      });

      return;
    }

    const users = await firstValueFrom(this.userService.findOrganisationMails(inspection.building.organisationId));

    const url = joinUrl(window.origin);
    const emailContent = await tryWithLoading(this.importing$, async () =>
      await firstValueFrom(this.inspectionService.generateInspectionEmail(inspection.id, url)),
    );

    const dialogRef = this.nbDialogService.open(SendEmailComponent, {
      context: {
        title: 'E-Mail senden',
        message: 'Bist du sicher, dass du die E-Mail an die/den Empfänger senden willst?',
        emailMessage: emailContent,
        multipleReceivers: true,
        receiverUsers: users,
      },
    });
    const message = await firstValueFrom(dialogRef.onClose);
    if (!message) {
      this.nbToastrService.info('Es wurde keine E-Mail versendet', 'E-Mail abgebrochen');
      return;
    }
    message.inspectionId = inspection.id;

    if (message) {
      this.inspectionService.sendMail(message).subscribe(async () => {
        this.inspection!.status = 'SENT';
        await this.initForm(this.inspection);

        this.nbToastrService.success('E-Mail wurde erfolgreich versendet', 'E-Mail versendet');
      });
    }
  }

  private async checkAutosave(): Promise<void> {
    if (this.autoSaveEnabled() && this.formWrapper.dirty && !this.hasAnyPendingAttachments) {
      await this.saveInspection(true);
    }
  }

  private async mergeInspectionAndWaitForJobToComplete(inspectionMergeDto: InspectionMergeDto): Promise<InspectionDetailDto> {
    const { jobId } = await firstValueFrom(this.inspectionService.mergeAsync(inspectionMergeDto));
    const promise = new Promise<InspectionDetailDto>((resolve, reject) => {
      const interval = setInterval(async () => {
        const job = await firstValueFrom(this.inspectionService.mergeAsyncStatus(jobId));
        if (job.status === AsyncMergeJobInspectionDetailDto.StatusEnum.Completed) {
          clearInterval(interval);
          resolve(job.result!);
        } else if (job.status === AsyncMergeJobInspectionDetailDto.StatusEnum.Failed) {
          clearInterval(interval);
          reject(job.error);
        }
      }, 1000);
    });
    return await promise;
  }
}
