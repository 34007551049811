<div class="full-screen-panel" [class.full-screen]="fullScreen()">
  <button class="full-screen-button"
          *ngIf="!fullScreen()"
          nbButton
          size="large"
          ghost
          (click)="enterFullScreen()">
    <nb-icon pack="eva" icon="expand-outline"></nb-icon>
  </button>
  <button class="full-screen-button"
          *ngIf="fullScreen()"
          nbButton
          size="large"
          ghost
          (click)="exitFullScreen()">
    <nb-icon pack="eva" icon="close-outline"></nb-icon>
  </button>
  <ng-content></ng-content>
</div>
