import { inject, Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { firstValueFrom } from 'rxjs';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';

@Injectable()
export class DialogService {
  private readonly dialogService = inject(NbDialogService);

  async showDeleteDialog(title: string, message: string): Promise<boolean> {
    try {
      return !!await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
        context: {
          title,
          message,
        },
      }).onClose);
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
