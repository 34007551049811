import { NgModule } from '@angular/core';
import { CheckpointOverviewPageComponent } from './checkpoint-overview-page/checkpoint-overview-page.component';
import { CheckpointDetailPageComponent } from './checkpoint-detail-page/checkpoint-detail-page.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { UserDetailPageComponent } from './user-detail-page/user-detail-page.component';
import {
  CheckpointGroupOverviewPageComponent,
} from './checkpoint-group-overview-page/checkpoint-group-overview-page.component';
import {
  CheckpointGroupDetailPageComponent,
} from './checkpoint-group-detail-page/checkpoint-group-detail-page.component';
import { OrganisationOverviewPageComponent } from './organisation-overview-page/organisation-overview-page.component';
import { OrganisationDetailPageComponent } from './organisation-detail-page/organisation-detail-page.component';
import { BuildingOverviewPageComponent } from './building-overview-page/building-overview-page.component';
import { BuildingDetailPageComponent } from './building-detail-page/building-detail-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { EquipmentOverviewPageComponent } from './equipment-overview-page/equipment-overview-page.component';
import { EquipmentDetailPageComponent } from './equipment-detail-page/equipment-detail-page.component';
import {
  BuildingEquipmentOverviewPageComponent,
} from './building-equipment-overview-page/building-equipment-overview-page.component';
import {
  BuildingEquipmentDetailPageComponent,
} from './building-equipment-detail-page/building-equipment-detail-page.component';
import { InspectionOverviewPageComponent } from './inspection-overview-page/inspection-overview-page.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { DebugPageComponent } from './debug-page/debug-page.component';
import { SharedModule } from '@shared/shared.module';
import { InspectionDetailPageV2Component } from './inspection-detail-page-v2/inspection-detail-page-v2.component';
import { DetailFormComponent } from './inspection-detail-page-v2/detail-form/detail-form.component';
import {
  InspectionEntriesComponent,
} from './inspection-detail-page-v2/inspection-entries/inspection-entries.component';
import {
  InspectionEntryListItemComponent,
} from './inspection-detail-page-v2/inspection-entries/inspection-entry-group-list/inspection-entry-list-item/inspection-entry-list-item.component';
import {
  InspectionEntryFormComponent,
} from './inspection-detail-page-v2/inspection-entries/inspection-entry-form/inspection-entry-form.component';
import {
  InspectionEntryGroupListComponent,
} from './inspection-detail-page-v2/inspection-entries/inspection-entry-group-list/inspection-entry-group-list.component';
import { InspectionPdfsComponent } from './inspection-detail-page-v2/inspection-pdfs/inspection-pdfs.component';
import {
  InspectionPdfEntryComponent,
} from './inspection-detail-page-v2/inspection-pdfs/inspection-pdf-entry/inspection-pdf-entry.component';
import { TagOverviewPageComponent } from './tag-overview-page/tag-overview-page.component';
import { TagDetailPageComponent } from './tag-detail-page/tag-detail-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { CustomFieldsSettingsPageComponent } from './custom-fields-settings-page/custom-fields-settings-page.component';
import {
  CustomFieldCollectionSplitViewComponent,
} from '@pages/custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-split-view.component';
import {
  CustomFieldSplitViewComponent,
} from './custom-fields-settings-page/custom-field-split-view/custom-field-split-view.component';
import {
  CustomFieldFormComponent,
} from './custom-fields-settings-page/custom-field-split-view/custom-field-form/custom-field-form.component';
import {
  CustomFieldDialogComponent,
} from './custom-fields-settings-page/custom-field-split-view/custom-field-dialog/custom-field-dialog.component';
import {
  CustomFieldCollectionDialogComponent,
} from './custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-dialog/custom-field-collection-dialog.component';
import {
  CustomFieldCollectionFormComponent,
} from './custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-form/custom-field-collection-form.component';
import { ContractorOverviewPageComponent } from './contractor-overview-page/contractor-overview-page.component';
import { ContractorDetailPageComponent } from './contractor-detail-page/contractor-detail-page.component';
import { HauFormsModule } from '@sonofabit/ng-core';
import { MapOverviewComponent } from '@pages/map-overview/map-overview.component';
import { MapOverviewPopupPanelComponent } from './map-overview-popup-panel/map-overview-popup-panel.component';
import { RouterModule } from '@angular/router';
import { ContractorLoginPageComponent } from './contractor-login-page/contractor-login-page.component';
import {
  BuildingRepairTaskOverviewPageComponent,
} from './building-repair-task-overview-page/building-repair-task-overview-page.component';
import {
  BuildingEquipmentsCockpitComponent,
} from './inspection-detail-page-v2/building-equipments-cockpit/building-equipments-cockpit.component';
import {
  CockpitEntryDetailViewComponent,
} from './inspection-detail-page-v2/building-equipments-cockpit/cockpit-entry-detail-view/cockpit-entry-detail-view.component';
import {
  BuildingEquipmentDetailFormComponent,
} from './building-equipment-detail-page/building-equipment-detail-form/building-equipment-detail-form.component';
import { ArchivePageComponent } from './archive-page/archive-page.component';
import { TagListComponent } from '@pages/tag-list/tag-list.component';
import { MetaDataComponent } from './inspection-detail-page-v2/meta-data/meta-data.component';
import {
  MaintenanceTimerOverviewComponent,
} from './building-equipment-detail-page/maintenance-timer-overview/maintenance-timer-overview.component';
import {
  MaintenanceTimerComponent,
} from './building-equipment-detail-page/maintenance-timer/maintenance-timer.component';
import {
  MaintenanceTimerOverviewPageComponent,
} from '@pages/maintenance-timer-overview-page/maintenance-timer-overview-page.component';
import { UserDashboardPageComponent } from './user-dashboard-page/user-dashboard-page.component';
import {
  UserDashboardInspectionOverviewComponent,
} from './user-dashboard-page/user-dashboard-inspection-overview/user-dashboard-inspection-overview.component';
import {
  UserDashboardBuildingOverviewComponent,
} from './user-dashboard-page/user-dashboard-building-overview/user-dashboard-building-overview.component';
import {
  UserDashboardRepairTaskOverviewComponent,
} from './user-dashboard-page/user-dashboard-repair-task-overview/user-dashboard-repair-task-overview.component';
import { CooperativeDashboardPageComponent } from './cooperative-dashboard-page/cooperative-dashboard-page.component';
import {
  CooperativeDashboardInspectionOverviewComponent,
} from '@pages/cooperative-dashboard-page/cooperative-dashboard-inspection-overview/cooperative-dashboard-inspection-overview.component';
import {
  CooperativeDashboardBuildingOverviewComponent,
} from './cooperative-dashboard-page/cooperative-dashboard-building-overview/cooperative-dashboard-building-overview.component';
import {
  CooperativeDashboardRepairTaskOverviewComponent,
} from './cooperative-dashboard-page/cooperative-dashboard-repair-task-overview/cooperative-dashboard-repair-task-overview.component';
import { EmployeeStatisticsPageComponent } from '@pages/employee-statistics-page/employee-statistics-page.component';
import {
  CooperativeStatisticsPageComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-page.component';
import {
  CooperativeStatisticsInspectionOverviewComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-inspection-overview/cooperative-statistics-inspection-overview.component';
import {
  CooperativeStatisticsBuildingOverviewComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-building-overview/cooperative-statistics-building-overview.component';
import {
  CooperativeStatisticsRepairTaskOverviewComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-repair-task-overview/cooperative-statistics-repair-task-overview.component';
import {
  CooperativeStatisticsChartsComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-charts/cooperative-statistics-charts.component';
import {
  InspectionChartComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-charts/inspection-chart/inspection-chart.component';
import {
  RepairTaskChartComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-charts/repair-task-chart/repair-task-chart.component';
import { SharePdfPageComponent } from './share-pdf-page/share-pdf-page.component';
import { TemplateOverviewPageComponent } from '@pages/template-overview-page/template-overview-page.component';
import { TemplateDetailPageComponent } from './template-detail-page/template-detail-page.component';
import { NotificationOverviewPageComponent } from './notification-overview-page/notification-overview-page.component';
import { NotificationsModule } from '@/notifications/notifications.module';
import {
  CooperativeUserOverviewPageComponent,
} from '@pages/cooperative-user-overview-page/cooperative-user-overview-page.component';
import {
  CooperativeBuildingOverviewPageComponent,
} from '@pages/cooperative-building-overview-page/cooperative-building-overview-page.component';
import {
  CooperativeInspectionOverviewPageComponent,
} from '@pages/cooperative-inspection-overview-page/cooperative-inspection-overview-page.component';
import {
  CooperativeBuildingEquipmentOverviewPageComponent,
} from '@pages/cooperative-building-equipment-overview-page/cooperative-building-equipment-overview-page.component';
import {
  PropertyManagerBuildingOverviewPageComponent,
} from '@pages/property-manager-building-overview-page/property-manager-building-overview-page.component';
import {
  PropertyManagerInspectionOverviewPageComponent,
} from '@pages/property-manager-inspection-overview-page/property-manager-inspection-overview-page.component';
import {
  PropertyManagerBuildingEquipmentOverviewPageComponent,
} from '@pages/property-manager-building-equipment-overview-page/property-manager-building-equipment-overview-page.component';
import {
  CooperativeMaintenanceTimerOverviewPageComponent,
} from '@pages/cooperative-maintenance-timer-overview-page/cooperative-maintenance-timer-overview-page.component';
import {
  PropertyManagerMaintenanceTimerOverviewPageComponent,
} from '@pages/property-manager-maintenance-timer-overview-page/property-manager-maintenance-timer-overview-page.component';
import { UserOverviewPageComponent } from '@pages/user-overview-page/user-overview-page.component';
import { EmailOverviewPageComponent } from './email-overview-page/email-overview-page.component';
import { EmailDetailPageComponent } from './email-detail-page/email-detail-page.component';
import {
  MaintenanceTimerDashboardPageComponent,
} from './maintenance-timer-dashboard-page/maintenance-timer-dashboard-page.component';
import {
  MaintenanceTimerDashboardFilterComponent,
} from './maintenance-timer-dashboard-page/maintenance-timer-dashboard-filter/maintenance-timer-dashboard-filter.component';
import {
  MaintenanceTimerDashboardListComponent,
} from './maintenance-timer-dashboard-page/maintenance-timer-dashboard-list/maintenance-timer-dashboard-list.component';
import {
  MaintenanceTimerStatisticsPageComponent,
} from './maintenance-timer-statistics-page/maintenance-timer-statistics-page.component';
import {
  MaintenanceTimerCalendarPageComponent,
} from './maintenance-timer-calendar-page/maintenance-timer-calendar-page.component';
import {
  MaintenanceTimerCalendarCellComponent,
} from './maintenance-timer-calendar-page/maintenance-timer-calendar-cell/maintenance-timer-calendar-cell.component';
import {
  MaintenanceTimerDialogComponent,
} from './maintenance-timer-dashboard-page/maintenance-timer-dialog/maintenance-timer-dialog.component';
import {
  ContractorExternalDashboardPageComponent,
} from './contractor-external-dashboard-page/contractor-external-dashboard-page.component';
import {
  ContractorExternalDashboardArchiveComponent,
} from './contractor-external-dashboard-page/contractor-external-dashboard-archive/contractor-external-dashboard-archive.component';
import {
  MaintenanceTimerEditDialogComponent,
} from './maintenance-timer-dashboard-page/maintenance-timer-edit-dialog/maintenance-timer-edit-dialog.component';
import { BuildingScanDetailPageComponent } from './building-scan-detail-page/building-scan-detail-page.component';
import {
  BuildingScanPoiNavigatorComponent,
} from './building-scan-detail-page/building-scan-poi-navigator/building-scan-poi-navigator.component';
import { BuildingScanPoiListComponent } from './building-scan-detail-page/building-scan-poi-navigator/building-scan-poi-list/building-scan-poi-list.component';
import { BuildingScanEquipmentTreeViewComponent } from './building-scan-detail-page/building-scan-poi-navigator/building-scan-equipment-tree-view/building-scan-equipment-tree-view.component';
import { BuildingScanEquipmentTreeViewEntryComponent } from './building-scan-detail-page/building-scan-poi-navigator/building-scan-equipment-tree-view/building-scan-equipment-tree-view-entry/building-scan-equipment-tree-view-entry.component';
import { BuildingDashboardModule } from '@pages/building-dashboard/building-dashboard.module';



@NgModule({
  declarations: [
    LoginPageComponent,
    LogoutPageComponent,
    CheckpointOverviewPageComponent,
    CheckpointDetailPageComponent,
    BuildingOverviewPageComponent,
    BuildingDetailPageComponent,
    UserOverviewPageComponent,
    UserDetailPageComponent,
    CheckpointGroupOverviewPageComponent,
    CheckpointGroupDetailPageComponent,
    OrganisationOverviewPageComponent,
    OrganisationDetailPageComponent,
    EquipmentOverviewPageComponent,
    EquipmentDetailPageComponent,
    BuildingEquipmentOverviewPageComponent,
    BuildingEquipmentDetailPageComponent,
    InspectionOverviewPageComponent,
    DebugPageComponent,
    InspectionDetailPageV2Component,
    DetailFormComponent,
    InspectionEntriesComponent,
    InspectionEntryListItemComponent,
    InspectionEntryFormComponent,
    InspectionEntryGroupListComponent,
    SettingsPageComponent,
    TagOverviewPageComponent,
    TagDetailPageComponent,
    InspectionPdfsComponent,
    InspectionPdfEntryComponent,
    CustomFieldsSettingsPageComponent,
    CustomFieldCollectionSplitViewComponent,
    CustomFieldSplitViewComponent,
    CustomFieldFormComponent,
    CustomFieldDialogComponent,
    CustomFieldCollectionDialogComponent,
    CustomFieldCollectionFormComponent,
    ContractorOverviewPageComponent,
    ContractorDetailPageComponent,
    ArchivePageComponent,
    TagListComponent,
    BuildingEquipmentsCockpitComponent,
    CockpitEntryDetailViewComponent,
    BuildingEquipmentDetailFormComponent,
    ContractorLoginPageComponent,
    BuildingRepairTaskOverviewPageComponent,
    MetaDataComponent,
    MapOverviewComponent,
    MapOverviewPopupPanelComponent,
    MaintenanceTimerOverviewComponent,
    MaintenanceTimerComponent,
    UserDashboardPageComponent,
    UserDashboardInspectionOverviewComponent,
    UserDashboardBuildingOverviewComponent,
    UserDashboardRepairTaskOverviewComponent,
    CooperativeDashboardPageComponent,
    CooperativeDashboardInspectionOverviewComponent,
    CooperativeDashboardBuildingOverviewComponent,
    CooperativeDashboardRepairTaskOverviewComponent,
    MaintenanceTimerOverviewPageComponent,
    EmployeeStatisticsPageComponent,
    CooperativeStatisticsPageComponent,
    CooperativeStatisticsInspectionOverviewComponent,
    CooperativeStatisticsBuildingOverviewComponent,
    CooperativeStatisticsRepairTaskOverviewComponent,
    CooperativeStatisticsChartsComponent,
    InspectionChartComponent,
    RepairTaskChartComponent,
    SharePdfPageComponent,
    TemplateOverviewPageComponent,
    TemplateDetailPageComponent,
    CooperativeUserOverviewPageComponent,
    CooperativeBuildingOverviewPageComponent,
    CooperativeInspectionOverviewPageComponent,
    CooperativeBuildingEquipmentOverviewPageComponent,
    PropertyManagerBuildingOverviewPageComponent,
    PropertyManagerInspectionOverviewPageComponent,
    PropertyManagerBuildingEquipmentOverviewPageComponent,
    CooperativeMaintenanceTimerOverviewPageComponent,
    PropertyManagerMaintenanceTimerOverviewPageComponent,
    UserOverviewPageComponent,
    EmailOverviewPageComponent,
    EmailDetailPageComponent,
    NotificationOverviewPageComponent,
    MaintenanceTimerDashboardPageComponent,
    MaintenanceTimerDashboardFilterComponent,
    MaintenanceTimerDashboardListComponent,
    MaintenanceTimerCalendarPageComponent,
    MaintenanceTimerCalendarCellComponent,
    MaintenanceTimerDialogComponent,
    MaintenanceTimerStatisticsPageComponent,
    ContractorExternalDashboardPageComponent,
    ContractorExternalDashboardArchiveComponent,
    MaintenanceTimerEditDialogComponent,
    BuildingScanDetailPageComponent,
    BuildingScanPoiNavigatorComponent,
    BuildingScanPoiListComponent,
    BuildingScanEquipmentTreeViewComponent,
    BuildingScanEquipmentTreeViewEntryComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ComponentsModule,
    SharedModule,
    HauFormsModule,
    RouterModule,
    NotificationsModule,
    BuildingDashboardModule,
  ],
  exports: [
    CustomFieldFormComponent,
  ],
})
export class PagesModule {
}
