import { Component, Input, inject } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';
import { Router } from '@angular/router';
import { BuildingScanItemDto } from '@artemis-software/wr-api';

@Component({
  selector: 'wr-building-scan-row',
  templateUrl: './building-scan-row.component.html',
  styleUrls: ['./building-scan-row.component.scss'],
})
export class BuildingScanRowComponent {

  readonly router = inject(Router);

  @Input()
  @Tracked()
  scan!: BuildingScanItemDto;

  goToDetail() {
    this.router.navigate(['scan', this.scan.id]);
  }
}
