import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { Tracked } from '@/utils/signals/tracked';

enum ListType {
  TGA = 'TGA',
  POI = 'POI',
}

@Component({
  selector: 'wr-building-scan-poi-navigator',
  templateUrl: './building-scan-poi-navigator.component.html',
  styleUrls: ['./building-scan-poi-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScanPoiNavigatorComponent {
  readonly ListType = ListType;
  readonly selectedList = signal<ListType>(ListType.TGA);

  @Tracked()
  @Input()
  scanId: string = '';

  @Input()
  @Tracked()
  reloadValue = 0;
}
