import type { IPointOfInterest, IScan, ScanProvider } from '@wr/scan-renderer';
import {
  BuildingScanService,
  PointOfInterestDetailDto,
  PointOfInterestEditDto,
  PointOfInterestService,
} from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { DialogService } from '@services/dialog-service';

export class ScanViewerAdapter implements ScanProvider {
  constructor(
    readonly scanId: string,
    readonly scanService: BuildingScanService,
    readonly pointOfInterestService: PointOfInterestService,
    readonly dialogService: DialogService,
  ) {
  }

  async loadScan(): Promise<IScan> {
    const response = await firstValueFrom(this.scanService.getBuildingScanById(this.scanId));

    return {
      id: response.id,
      panoramas: response.panoramas.map(panorama => {
        const { id, transform: pose, previewUrl, imageUrl, chunksX, chunksY, chunks } = panorama;

        return { id, pose, previewUrl, imageUrl, chunksX, chunksY, chunks };
      }),
      pointsOfInterest: response.pointsOfInterest
    };
  }

  private convertPointOfInterest(entity: PointOfInterestDetailDto): IPointOfInterest {
    return {
      id: entity.id,
      name: entity.name,
      position: entity.position,
      panoramaId: entity.panoramaId,
      buildingEquipmentId: entity.buildingEquipment?.id,
      maintenanceTimerId: entity.maintenanceTimer?.id,
    };
  }

  async editPointOfInterest(id: string, options: PointOfInterestEditDto) {
    return this.convertPointOfInterest(
      await firstValueFrom(this.pointOfInterestService.update(id, options))
    );
  }

  async getPOIDetails(id: string) {
    return await firstValueFrom(this.pointOfInterestService.getById(id));
  }

  async deletePointOfInterest(id: string): Promise<boolean> {
    try {
      if (await this.dialogService.showDeleteDialog(
        'Point of Interest löschen',
        'Bist du sicher, dass du diesen Point of Interest löschen willst?',
      )) {
        await firstValueFrom(this.pointOfInterestService.deletePointOfInterest(id));
        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }
}
