import {
  Component,
  ContentChild, ContentChildren,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  QueryList, Signal, signal,
  TemplateRef, ViewContainerRef,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  firstValueFrom,
  from,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { LocalStorageSubject } from '@/utils/local-storage-subject';
import { DataColumnDirective } from './data-column.directive';
import { NbComponentStatus, NbIconConfig } from '@nebular/theme';
import { tryWithLoading } from '@/utils/async-utils';
import { Tracked } from '@/utils/signals/tracked';
import { SortDto } from '@artemis-software/wr-api';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableFilterDirective } from '@shared/generic/data-table/data-table-filter.directive';

interface BaseFilter {
  sort?: SortDto;
  page?: number;
  size?: number;
}

export interface CustomAction {
  icon?: NbIconConfig;
  title?: string;
  status: NbComponentStatus;
  tooltip?: string;
  action: () => unknown;
}
@Component({
  selector: 'wr-new-data-table[loadItemsFunction][getCountFunction][localStorageIdentifier]',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<Entity, Filter extends BaseFilter> implements OnInit {
  @ContentChild(DataTableFilterDirective)
  filterForm!: DataTableFilterDirective;
  @Input()
  form: FormGroup;

  readonly pageSizes = [10, 20, 50, 100];
  readonly ceil = (n: number): number => Math.ceil(n);

  @ContentChildren(DataColumnDirective)
  @Tracked()
  columns?: QueryList<DataColumnDirective>;

  columnsFiltered = asyncComputed(async () => {
    const columns = this.columns ?? [];
    const filtered: DataColumnDirective[] = [];
    for (const column of columns) {
      filtered.push(column);
    }
    return filtered;
  });

  @Input()
  insideCard = true;
  @Input()
  headerText?: string;
  @Input()
  localStorageIdentifier!: string;
  @Input()
  loadItemsFunction!: (filter: Filter) => Observable<Entity[]>;
  @Input()
  getCountFunction!: (filter: Filter) => Observable<number>;
  @Input()
  showNewButton = true;
  @Input()
  isAdmin: Signal<boolean> = signal(false);
  @Input()
  @Tracked()
  customActions: CustomAction[] = [];

  @Output()
  lineClick = new EventEmitter<Entity>();
  @Output()
  newClick = new EventEmitter();

  data$?: Observable<{ count: number; items: Entity[] }>;

  pageSize$!: LocalStorageSubject<number>;
  page$!: LocalStorageSubject<number>;
  sorting$!: LocalStorageSubject<SortDto | undefined>;
  filter$ = new BehaviorSubject<Filter>({} as Filter);
  loading$ = new BehaviorSubject(false);
  refresh$ = new BehaviorSubject<void>(undefined);

  ngOnInit(): void {
    const prefix = this.localStorageIdentifier ? `${this.localStorageIdentifier}-` : '';
    this.pageSize$ = new LocalStorageSubject(prefix + 'page-size', this.pageSizes[0]);
    this.page$ = new LocalStorageSubject(prefix + 'page', 0);
    this.sorting$ = new LocalStorageSubject<SortDto | undefined>(prefix + 'sorting', undefined);
    this.recoverFilter();
    this.filter$.subscribe(() => this.persistFilter());

    this.data$ = combineLatest([
      this.pageSize$,
      this.page$,
      this.sorting$,
      this.filter$,
      this.refresh$,
    ]).pipe(
      debounceTime(100),
      tap(() => this.loading$.next(true)),
      map(([pageSize, page, sorting, filter]) => {
        return {
          sort: sorting,
          page,
          size: pageSize,
          ...filter,
        };
      }),
      switchMap((filter) => {
        const promise = tryWithLoading(this.loading$, async () => {
          const items = await firstValueFrom(this.loadItemsFunction(filter));
          const count = await firstValueFrom(this.getCountFunction(filter));
          return { items, count };
        });
        return from(promise);
      }),
      shareReplay(1),
    );

    this.filter$.subscribe(() => this.page$.next(0));
  }

  constructor(private formBuilder: FormBuilder, private vcr: ViewContainerRef) {
    this.form = this.formBuilder.group({});
  }

  toggleSort(column: DataColumnDirective): void {
    if (!column.sortable) {
      return;
    }

    const current = this.sorting$.value;
    this.sorting$.next({
      sort: column.key,
      order:
        current?.order === SortDto.OrderEnum.Asc && current?.sort === column.key
          ? SortDto.OrderEnum.Desc
          : SortDto.OrderEnum.Asc,
    });
  }

  refresh(): void {
    this.refresh$.next(undefined);
  }

  changePageSize(pageSize: number) {
    this.pageSize$.next(pageSize);
    this.page$.next(0);
  }

  onSubmit(): void {
    this.filter$.next(this.form.value);
  }

  recoverFilter(): void {
    const filter = localStorage.getItem(this.localStorageIdentifier);
    if (filter) {
      this.form.patchValue(JSON.parse(filter));
    }
  }

  persistFilter(): void {
    localStorage.setItem(this.localStorageIdentifier, JSON.stringify(this.form.value));
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent): void {
    this.onSubmit();
  }
}
