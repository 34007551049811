import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { PendingChanges } from '@guards/pending-changes.guard';
import { routeParam } from '@/utils/signals/router';
import { loader } from '@/utils/signals/loader';
import { PointOfInterestService, } from '@artemis-software/wr-api';
import { firstValueFrom } from 'rxjs';
import { createScanRenderer } from '@wr/scan-renderer';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';


@Component({
  selector: 'wr-building-scan-detail-page',
  templateUrl: './building-scan-detail-page.component.html',
  styleUrls: ['./building-scan-detail-page.component.scss'],
  providers: [ScanViewerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScanDetailPageComponent implements PendingChanges, OnInit, OnDestroy {

  @ViewChild('container', { static: true }) container!: ElementRef;

  private readonly scanViewerService = inject(ScanViewerService);

  readonly pointOfInterestService = inject(PointOfInterestService);

  readonly scanId = routeParam('scanId', true);

  readonly loading = loader();

  readonly reloadSignal = signal(0);

  scanViewer?: ReturnType<typeof createScanRenderer>;

  hasPendingChanges(): boolean | Promise<boolean> {
    return false;
  }

  async navigateBack(): Promise<void> {
    window.history.back();
  }

  async deletePointOfInterest(id: string) {
    try {
      await firstValueFrom(this.pointOfInterestService.deletePointOfInterest(id));

      this.scanViewer?.removePointOfInterest(id);
    } catch (e) {
      console.error(e);
    }
  }

  reload() {
    this.reloadSignal.set(this.reloadSignal() + 1);
  }

  ngOnInit() {
    if (this.container.nativeElement)
      this.scanViewerService.mount(this.container.nativeElement);
  }

  ngOnDestroy() {
  }
}
