<div class="building-scan-detail-page">
  <wr-full-screen-panel>
    <nb-card [nbSpinner]="loading()">
      <nb-card-header>
        <button nbButton ghost status="primary" (click)="navigateBack()">
          <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
        </button>
        <h5>Gebäudescan</h5>
      </nb-card-header>
      <nb-card-body>
        <div class="building-scan-split-view">
          <div class="building-scan-viewer noselect">
            <div #container class="building-scan-viewer"></div>
          </div>
          <div class="building-scan-list">
            <wr-building-scan-poi-navigator [scanId]="scanId()" [reloadValue]="reloadSignal()"></wr-building-scan-poi-navigator>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </wr-full-screen-panel>
</div>
