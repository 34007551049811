import { EventEmitter, inject, Injectable } from '@angular/core';
import { createScanRenderer, PickPointResult } from '@wr/scan-renderer';
import { ScanViewerAdapter } from '@pages/building-scan-detail-page/scan-viewer-adapter';
import { BuildingScanService, PointOfInterestDto, PointOfInterestService, Vector3 } from '@artemis-software/wr-api';
import { DialogService } from '@services/dialog-service';
import { firstValueFrom } from 'rxjs';
import { routeParam } from '@/utils/signals/router';
import { loader } from '@/utils/signals/loader';
import { asyncComputed } from '@/utils/signals/asyncComputed';
import { PickPointOptions } from '@wr/scan-renderer/dist/state/PickPointState';

export interface CreatePointOfInterestOptions {
  name: string;
  panoramaId: string;
  position: Vector3;
  buildingEquipmentId?: string;
  maintenanceTimerId?: string;
}


@Injectable()
export class ScanViewerService {
  private readonly scanService = inject(BuildingScanService);
  private readonly pointOfInterestService = inject(PointOfInterestService);
  private readonly dialogService = inject(DialogService);

  private adapter?: ScanViewerAdapter;
  private scanViewer?: ReturnType<typeof createScanRenderer>;

  readonly scanId = routeParam('scanId', true);

  readonly pointOfInterestAdded = new EventEmitter<PointOfInterestDto>();

  mount(container: HTMLElement) {
    if (this.scanViewer)
      this.unmount();

    this.scanViewer = createScanRenderer(
      this.adapter = new ScanViewerAdapter(
        this.scanId(),
        this.scanService,
        this.pointOfInterestService,
        this.dialogService
      ),
      container
    );
  }

  unmount() {
    this.scanViewer?.unmount();
    this.scanViewer = undefined;
  }

  async createPointOfInterest(options: CreatePointOfInterestOptions) {
    const pointOfInterest = await firstValueFrom(this.pointOfInterestService.create({
      scanId: this.scanId(),
      name: options.name,
      position: options.position,
      buildingEquipmentId: options?.buildingEquipmentId,
      maintenanceTimerId: options?.maintenanceTimerId,
      panoramaId: options.panoramaId,
    }));

    this.scanViewer?.addPointOfInterest(pointOfInterest);

    this.pointsOfInterest.reload();
    this.buildingEquipments.reload();
  }

  async pickPosition(options?: PickPointOptions): Promise<PickPointResult | null> {
    if (!this.scanViewer)
      return null;

    return this.scanViewer.pickPosition(options);
  }

  jumpToPointOfInterest(pointOfInterest: PointOfInterestDto) {
    this.scanViewer?.bringIntoView(pointOfInterest.position, pointOfInterest.panoramaId);
  }

  readonly pointsOfInterestsLoading = loader();

  readonly pointsOfInterest = asyncComputed(() => {
    const scanId = this.scanId();
    if (!scanId) {
      return [];
    }
    return this.pointOfInterestService.getAllForScan(scanId);
  }, [], this.pointsOfInterestsLoading);

  readonly buildingEquipmentsLoading = loader();

  readonly buildingEquipments = asyncComputed(() => {
    const scanId = this.scanId();
    if (!scanId)
      return [];

    return this.pointOfInterestService.getBuildingEquipmentsForScan(scanId);
  }, [], this.buildingEquipmentsLoading);
}
