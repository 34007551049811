import { ChangeDetectionStrategy, Component, HostListener, signal } from '@angular/core';

@Component({
  selector: 'wr-full-screen-panel',
  templateUrl: './full-screen-panel.component.html',
  styleUrls: ['./full-screen-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenPanelComponent {
  fullScreen = signal(false);

  @HostListener('window:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.exitFullScreen();
    }
  }

  exitFullScreen() {
    this.fullScreen.set(false);
  }

  enterFullScreen() {
    this.fullScreen.set(true);
  }
}
