import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { EmailItemDto, UserService } from '@artemis-software/wr-api';
import { Select, Store } from '@ngxs/store';
import { EmailState } from '@/store/email/email.state';
import { Router } from '@angular/router';
import { defineStoreUsage } from '@/utils/storeUsage';
import { ChangeEmailFilter } from '@/store/email/email.action';
import { CurrentUserService } from '@services/current-user.service';
import { NbDateService } from '@nebular/theme';

@Component({
  selector: 'wr-email-overview-page',
  templateUrl: './email-overview-page.component.html',
  styleUrls: ['./email-overview-page.component.scss']
})
export class EmailOverviewPageComponent {

  readonly EmailTypeEnum = EmailItemDto.EmailTypeEnum;

  emailStoreUsage = defineStoreUsage({
    filterAction: ChangeEmailFilter,
    countSelector: EmailState.count,
    loadingSelector: EmailState.emailsLoading
  });

  @Select(EmailState.filteredEmails)
  emails$! : Observable<EmailItemDto[]>;
  @Select(EmailState.count)
  count$!: Observable<number>;
  @Select(EmailState.emailsLoading)
  loading$!: Observable<boolean>;

  form!: FormGroup;

  private readonly emailFilter = 'emailFilter';

  constructor(
    private formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dateService: NbDateService<Date>
  ) {
    this.initFilter();
  }

  initFilter() {
    const filter = localStorage.getItem(this.emailFilter);
    if(filter) {
      const filterValue = JSON.parse(filter);
      this.initFilterForm(filterValue.text);
    } else {
      this.initFilterForm('');
    }
  }

  initFilterForm(text: string): void {
    const startOfMonth = this.dateService.getMonthStart(this.dateService.today());
    const endOfMonth = this.dateService.addMonth(startOfMonth, 2);

    this.userService.findCurrentUser().subscribe(user => {
      this.form = new FormGroup({
        text: this.formBuilder.control(text),
        selectedRange: new FormControl({
          start: startOfMonth,
          end: endOfMonth,
        }),
        emailType: this.formBuilder.control(null),
        emailFilter: this.formBuilder.control(''),
      });
      this.store.dispatch(new ChangeEmailFilter(this.form.value));
    });
  }

  triggerFilter() {
    this.store.dispatch(new ChangeEmailFilter(this.form.value));
    localStorage.setItem(this.emailFilter, JSON.stringify(this.form.value));
  }

  navigateToEmail(emailItemDto: EmailItemDto) {
    this.router.navigate(['/email', emailItemDto.id]);
  }
}
