import { ChangeDetectionStrategy, Component, computed, Input } from '@angular/core';
import { BuildingScanImportDto } from '@artemis-software/wr-api';
import { Tracked } from '@/utils/signals/tracked';

@Component({
  selector: 'wr-building-scan-pending-row',
  templateUrl: './building-scan-pending-row.component.html',
  styleUrls: ['./building-scan-pending-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingScanPendingRowComponent {
  @Input()
  @Tracked()
  scan!: BuildingScanImportDto;

  readonly loading = computed(() =>
    this.scan.status === BuildingScanImportDto.StatusEnum.InProgress
    || this.scan.status === BuildingScanImportDto.StatusEnum.Pending
    || this.scan.status === BuildingScanImportDto.StatusEnum.Uploading,
  );

  readonly loadingText = computed(() => {
    switch (this.scan.status) {
    case BuildingScanImportDto.StatusEnum.InProgress:
      return 'Wird verarbeitet';
    case BuildingScanImportDto.StatusEnum.Pending:
      return 'Warte auf Verarbeitung';
    case BuildingScanImportDto.StatusEnum.Uploading:
      return 'Wird hochgeladen';
    default:
      return 'Unbekannter Status';
    }
  });

  readonly isFailed = computed(() => this.scan.status === BuildingScanImportDto.StatusEnum.Failed);
}
