import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckpointOverviewPageComponent } from '@pages/checkpoint-overview-page/checkpoint-overview-page.component';
import { CheckpointDetailPageComponent } from '@pages/checkpoint-detail-page/checkpoint-detail-page.component';
import { UserDetailPageComponent } from '@pages/user-detail-page/user-detail-page.component';
import {
  CheckpointGroupOverviewPageComponent,
} from '@pages/checkpoint-group-overview-page/checkpoint-group-overview-page.component';
import {
  CheckpointGroupDetailPageComponent,
} from '@pages/checkpoint-group-detail-page/checkpoint-group-detail-page.component';
import {
  OrganisationOverviewPageComponent,
} from '@pages/organisation-overview-page/organisation-overview-page.component';
import { OrganisationDetailPageComponent } from '@pages/organisation-detail-page/organisation-detail-page.component';
import { BuildingOverviewPageComponent } from '@pages/building-overview-page/building-overview-page.component';
import { BuildingDetailPageComponent } from '@pages/building-detail-page/building-detail-page.component';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { AuthGuard } from '@guards/auth.guard';
import { NoAuthGuard } from '@guards/no-auth.guard';
import { EquipmentOverviewPageComponent } from '@pages/equipment-overview-page/equipment-overview-page.component';
import { EquipmentDetailPageComponent } from '@pages/equipment-detail-page/equipment-detail-page.component';
import {
  BuildingEquipmentOverviewPageComponent,
} from '@pages/building-equipment-overview-page/building-equipment-overview-page.component';
import {
  BuildingEquipmentDetailPageComponent,
} from '@pages/building-equipment-detail-page/building-equipment-detail-page.component';
import { InspectionOverviewPageComponent } from '@pages/inspection-overview-page/inspection-overview-page.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LogoutPageComponent } from '@pages/logout-page/logout-page.component';
import { DebugPageComponent } from '@pages/debug-page/debug-page.component';
import { PendingChangesGuard } from '@guards/pending-changes.guard';
import { InspectionDetailPageV2Component } from '@pages/inspection-detail-page-v2/inspection-detail-page-v2.component';
import { SettingsPageComponent } from '@pages/settings-page/settings-page.component';
import { TagOverviewPageComponent } from '@pages/tag-overview-page/tag-overview-page.component';
import { TagDetailPageComponent } from '@pages/tag-detail-page/tag-detail-page.component';
import {
  CustomFieldsSettingsPageComponent,
} from '@pages/custom-fields-settings-page/custom-fields-settings-page.component';
import {
  CustomFieldSplitViewComponent,
} from '@pages/custom-fields-settings-page/custom-field-split-view/custom-field-split-view.component';
import {
  CustomFieldCollectionSplitViewComponent,
} from '@pages/custom-fields-settings-page/custom-field-collection-split-view/custom-field-collection-split-view.component';
import { ContractorOverviewPageComponent } from '@pages/contractor-overview-page/contractor-overview-page.component';
import { ContractorDetailPageComponent } from '@pages/contractor-detail-page/contractor-detail-page.component';
import { MapOverviewComponent } from '@pages/map-overview/map-overview.component';
import { ArchivePageComponent } from '@pages/archive-page/archive-page.component';
import { ContractorLoginPageComponent } from '@pages/contractor-login-page/contractor-login-page.component';
import {
  BuildingRepairTaskOverviewPageComponent,
} from '@pages/building-repair-task-overview-page/building-repair-task-overview-page.component';
import {
  MaintenanceTimerOverviewPageComponent,
} from '@pages/maintenance-timer-overview-page/maintenance-timer-overview-page.component';
import { UserDashboardPageComponent } from '@pages/user-dashboard-page/user-dashboard-page.component';
import {
  CooperativeDashboardPageComponent,
} from '@pages/cooperative-dashboard-page/cooperative-dashboard-page.component';
import { TemplateOverviewPageComponent } from '@pages/template-overview-page/template-overview-page.component';
import { TemplateDetailPageComponent } from '@pages/template-detail-page/template-detail-page.component';
import { SharePdfPageComponent } from '@pages/share-pdf-page/share-pdf-page.component';
import { EmployeeStatisticsPageComponent } from '@pages/employee-statistics-page/employee-statistics-page.component';
import {
  CooperativeStatisticsPageComponent,
} from '@pages/cooperative-statistics-page/cooperative-statistics-page.component';
import {
  NotificationOverviewPageComponent,
} from '@pages/notification-overview-page/notification-overview-page.component';
import {
  CooperativeUserOverviewPageComponent,
} from '@pages/cooperative-user-overview-page/cooperative-user-overview-page.component';
import {
  CooperativeBuildingOverviewPageComponent,
} from '@pages/cooperative-building-overview-page/cooperative-building-overview-page.component';
import {
  CooperativeInspectionOverviewPageComponent,
} from '@pages/cooperative-inspection-overview-page/cooperative-inspection-overview-page.component';
import {
  CooperativeBuildingEquipmentOverviewPageComponent,
} from '@pages/cooperative-building-equipment-overview-page/cooperative-building-equipment-overview-page.component';
import {
  PropertyManagerBuildingOverviewPageComponent,
} from '@pages/property-manager-building-overview-page/property-manager-building-overview-page.component';
import {
  PropertyManagerInspectionOverviewPageComponent,
} from '@pages/property-manager-inspection-overview-page/property-manager-inspection-overview-page.component';
import {
  PropertyManagerBuildingEquipmentOverviewPageComponent,
} from '@pages/property-manager-building-equipment-overview-page/property-manager-building-equipment-overview-page.component';
import {
  CooperativeMaintenanceTimerOverviewPageComponent,
} from '@pages/cooperative-maintenance-timer-overview-page/cooperative-maintenance-timer-overview-page.component';
import {
  PropertyManagerMaintenanceTimerOverviewPageComponent,
} from '@pages/property-manager-maintenance-timer-overview-page/property-manager-maintenance-timer-overview-page.component';
import { UserOverviewPageComponent } from '@pages/user-overview-page/user-overview-page.component';
import { EmailOverviewPageComponent } from '@pages/email-overview-page/email-overview-page.component';
import { EmailDetailPageComponent } from '@pages/email-detail-page/email-detail-page.component';
import {
  MaintenanceTimerCalendarPageComponent,
} from '@pages/maintenance-timer-calendar-page/maintenance-timer-calendar-page.component';
import {
  MaintenanceTimerDashboardPageComponent,
} from '@pages/maintenance-timer-dashboard-page/maintenance-timer-dashboard-page.component';
import {
  ContractorExternalDashboardPageComponent,
} from '@pages/contractor-external-dashboard-page/contractor-external-dashboard-page.component';
import {
  MaintenanceTimerStatisticsPageComponent,
} from '@pages/maintenance-timer-statistics-page/maintenance-timer-statistics-page.component';
import { BuildingScanDetailPageComponent } from '@pages/building-scan-detail-page/building-scan-detail-page.component';
import { BuildingDashboardPageComponent } from '@pages/building-dashboard/building-dashboard-page.component';
import {
  BuildingDashboardOverviewComponent
} from '@pages/building-dashboard/building-dashboard-overview/building-dashboard-overview.component';
import {
  BuildingDashboardScansComponent
} from '@pages/building-dashboard/building-dashboard-scans/building-dashboard-scans.component';
import {
  BuildingDashboardMetricsComponent
} from '@pages/building-dashboard/building-dashboard-metrics/building-dashboard-metrics.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'pdf/:id',
    component: SharePdfPageComponent,
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'organisation',
        component: CooperativeDashboardPageComponent,
      },
      {
        path: 'user',
        component: UserDashboardPageComponent,
      },
      {
        path: 'building',
        component: BuildingDashboardPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: BuildingDashboardOverviewComponent,
          },
          {
            path: 'scans',
            component: BuildingDashboardScansComponent,
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'metrics',
            component: BuildingDashboardMetricsComponent,
          },

        ],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'maintenanceTimers',
        component: MaintenanceTimerDashboardPageComponent,
      },
    ],
  },
  {
    path: 'checkpoint/:id',
    component: CheckpointDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'checkpoints',
    component: CheckpointOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user/:id',
    component: UserDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'users',
    component: UserOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'checkpointGroup/:id',
    component: CheckpointGroupDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'checkpointGroups',
    component: CheckpointGroupOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation/:id',
    component: OrganisationDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'organisations',
    component: OrganisationOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buildings',
    component: BuildingOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'building/:id',
    component: BuildingDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'map',
    component: MapOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'equipments',
    component: EquipmentOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'equipment/:id',
    component: EquipmentDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'buildingEquipments',
    component: BuildingEquipmentOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buildingEquipment/:id',
    component: BuildingEquipmentDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'maintenanceTimers',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        path: 'overview',
        component: MaintenanceTimerOverviewPageComponent,
      }, {
        path: 'calendar',
        component: MaintenanceTimerCalendarPageComponent,
      },
    ],
  },
  {
    path: 'inspections',
    component: InspectionOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cooperative',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'inspections',
        component: CooperativeInspectionOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'maintenanceTimers',
        component: CooperativeMaintenanceTimerOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buildingEquipments',
        component: CooperativeBuildingEquipmentOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buildings',
        component: CooperativeBuildingOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        component: CooperativeUserOverviewPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'propertyManager',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'inspections',
        component: PropertyManagerInspectionOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'maintenanceTimers',
        component: PropertyManagerMaintenanceTimerOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buildingEquipments',
        component: PropertyManagerBuildingEquipmentOverviewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buildings',
        component: PropertyManagerBuildingOverviewPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'inspection/:id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'general',
      },
      {
        path: ':inspectionTab',
        component: InspectionDetailPageV2Component,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
      },
    ],
  },
  {
    path: 'tags',
    component: TagOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tag/:id',
    component: TagDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'contractors',
    component: ContractorOverviewPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contractor/:id',
    component: ContractorDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'contractor-view/:id',
    component: ContractorExternalDashboardPageComponent,
  },
  {
    path: 'contractor-login/:id',
    component: ContractorLoginPageComponent,
  },
  {
    path: 'building-repair-tasks/:id',
    component: BuildingRepairTaskOverviewPageComponent,
  },
  {
    path: 'templates',
    component: TemplateOverviewPageComponent,
  },
  {
    path: 'template/:id',
    component: TemplateDetailPageComponent,
  },
  {
    path: 'debug',
    component: DebugPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'emails',
    component: EmailOverviewPageComponent,
  },
  {
    path: 'email/:id',
    component: EmailDetailPageComponent,
  },
  {
    path: 'archive',
    component: ArchivePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'statistics',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'user', pathMatch: 'full' },
      {
        path: 'user',
        component: EmployeeStatisticsPageComponent,
      },
      {
        path: 'cooperative',
        component: CooperativeStatisticsPageComponent,
      },
      {
        path: 'maintenance-timer',
        component: MaintenanceTimerStatisticsPageComponent,
      },
    ],
  },
  {
    path: 'notifications',
    component: NotificationOverviewPageComponent,
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'defaults', pathMatch: 'full' },
      {
        path: 'defaults',
        component: SettingsPageComponent,
      },
      {
        path: 'customFields',
        component: CustomFieldsSettingsPageComponent,
        children: [
          { path: '', redirectTo: 'fields', pathMatch: 'full' },
          {
            path: 'fields',
            component: CustomFieldSplitViewComponent,
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'field-sets',
            component: CustomFieldCollectionSplitViewComponent,
            canDeactivate: [PendingChangesGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'scan/:scanId',
    component: BuildingScanDetailPageComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: '**',
    redirectTo: 'inspections',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
