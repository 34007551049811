<div class="building-equipment">
  <div class="node root" (click)="toggleCollapsed()">
    <nb-icon pack="eva" icon="cube-outline"></nb-icon>
    <div class="node-name">
      <span class="building-equipment-name">
        <span>{{ buildingEquipment.equipmentName }}</span>
        <span *ngIf="buildingEquipment.quantity > 1"> ({{ buildingEquipment.quantity }})</span>
      </span>
      <span class="building-equipment-section">{{ buildingEquipment.sectionText }}</span>
    </div>
    <ng-container [ngSwitch]="collapsed()">
      <nb-icon pack="eva" icon="chevron-down-outline" *ngSwitchCase="false"></nb-icon>
      <nb-icon pack="eva" icon="chevron-up-outline" *ngSwitchCase="true"></nb-icon>
    </ng-container>
  </div>
  <div class="children" *ngIf="collapsed()">
    <div class="node maintenance-timer"
         *ngFor="let maintenanceTimer of buildingEquipment.maintenanceTimers"
         (click)="maintenanceTimer.associatedPointOfInterest && jumpToPointOfInterest(maintenanceTimer.associatedPointOfInterest)">
      <nb-icon pack="eva" icon="clock-outline"></nb-icon>
      <nb-icon pack="eva" icon="pin-outline" *ngIf="maintenanceTimer.associatedPointOfInterest"></nb-icon>
      <div class="node-name">Wartungstimer</div>
      <button nbButton
              *ngIf="!maintenanceTimer.associatedPointOfInterest"
              status="info"
              size="small"
              ghost
              (click)="createPointOfInterestForMaintenanceTimer(buildingEquipment, maintenanceTimer)">
        <nb-icon pack="eva" icon="plus-outline"></nb-icon>
        POI hinzufügen
      </button>
    </div>
    <div class="node point-of-interest"
         *ngFor="let pointOfInterest of buildingEquipment.associatedPointsOfInterest"
         (click)="jumpToPointOfInterest(pointOfInterest)">
      <nb-icon pack="eva" icon="pin-outline"></nb-icon>
      <div class="node-name">POI</div>
    </div>
    <div class="add-poi">
      <button nbButton
              status="primary"
              size="small"
              ghost
              (click)="createPointOfInterestForBuildingEquipment(buildingEquipment)">
        <nb-icon pack="eva" icon="plus-outline"></nb-icon>
        POI hinzufügen
      </button>
    </div>
  </div>
</div>
