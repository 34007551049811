<div class="detail-container">
  <nb-card [nbSpinner]="!!(saving$|async)">
    <nb-card-header>
      <button nbButton ghost status="primary" (click)="navigateBack()">
        <nb-icon pack="eva" icon="arrow-back-outline"></nb-icon>
      </button>
      <h5>Gebäude</h5>
      <wr-edit-delete-button-group *ngIf="isAdmin()" (edit)="onEdit()"
                                   (delete)="onDelete()"></wr-edit-delete-button-group>
    </nb-card-header>
    <nb-card-body>
      <ng-container *ngIf="form">
        <nb-tabset>
          <nb-tab tabTitle="Grunddaten">
            <form [formGroup]="form">
              <div>
                <ng-container>
                  <div class="grid">
                    <div class="col-6 md:col-4">
                      <label class="label" for="name">Name</label>
                      <input fullWidth id="name" nbInput formControlName="name">
                      <div class="error" *ngIf="hasError('name')">Name ist erforderlich!</div>
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="buildingType">Gebäudetyp</label>
                      <wr-building-type-select id="buildingType"
                                               formControlName="buildingType"></wr-building-type-select>
                      <div class="error" *ngIf="hasError('buildingType')">Gebäudetyp ist erforderlich!</div>
                    </div>
                    <div class="col-6 md:col-4" *ngIf="displayOtherBuildingType()">
                      <label class="label" for="otherBuildingType">Anderer Typ</label>
                      <input fullWidth id="otherBuildingType" nbInput formControlName="otherBuildingType">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="internalNumber">Objektnummer Intern</label>
                      <input fullWidth id="internalNumber" nbInput
                             formControlName="internalNumber">
                      <div class="error" *ngIf="hasError('internalNumber')">Interne Nummer bereits vorhanden!</div>
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="externalNumber">Objektnummer Extern</label>
                      <input fullWidth id="externalNumber" nbInput
                             formControlName="externalNumber">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="organisation">Genossenschaft</label>
                      <wr-organisation-select id="organisation"
                                              formControlName="organisationId"></wr-organisation-select>
                      <div class="error" *ngIf="hasError('organisationId')">Genossenschaft ist erforderlich!</div>
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="buildingClass">Gebäudeklasse</label>
                      <wr-building-class-select fullWidth id="buildingClass"
                                                formControlName="buildingClass"></wr-building-class-select>
                      <div class="error" *ngIf="hasError('buildingClass')">Gebäudeklasse ist erforderlich!</div>
                    </div>
                    <div class="col-6 md:col-4" *ngIf="displayOtherBuildingClass()">
                      <label class="label" for="otherBuildingClass">Andere Klasse</label>
                      <input fullWidth id="otherBuildingClass" nbInput formControlName="otherBuildingClass">
                    </div>
                  </div>
                </ng-container>

                <ng-container>
                  <h6>Weitere Daten</h6>

                  <div class="grid">
                    <div class="col-6 md:col-4">
                      <label class="label" for="owner">Eigentümer</label>
                      <input id="owner" type="text" fullWidth nbInput formControlName="owner">
                    </div>
                    <div class="col-6 md:col-4">
                      <div class="propertyManagerGroup">
                        <label class="label" for="propertyManager">Hausverwalter</label>
                        <nb-form-field class="propertyManagerContent">
                          <input id="propertyManager"
                                 fullWidth
                                 type="text"
                                 nbInput
                                 [value]="getPropertyManagerName()"
                                 [disabled]="!isEditing"
                                 readonly>
                          <button nbSuffix *ngIf="isEditing" nbButton
                                  nbTooltip="Hausverwalter auswählen"
                                  (click)="openPropertyManagerDialog()">
                            <nb-icon pack="eva" icon="edit-outline"></nb-icon>
                          </button>
                        </nb-form-field>
                      </div>
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="propertyCaretaker">Hausbetreuer</label>
                      <input id="propertyCaretaker" fullWidth type="text" nbInput
                             formControlName="propertyCaretaker">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="assistant">Assistenz</label>
                      <input id="assistant" type="text" fullWidth nbInput formControlName="assistant">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="propertyKey">Schlüssel</label>
                      <input id="propertyKey" type="text" fullWidth nbInput formControlName="propertyKey">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="keySafeWithPosition">Schlüsselsafe mit Position</label>
                      <input id="keySafeWithPosition" type="text" fullWidth nbInput
                             formControlName="keySafeWithPosition">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="codes">Codes</label>
                      <input id="codes" type="text" fullWidth nbInput formControlName="codes">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="businesses">Geschäftslokale</label>
                      <input id="businesses" type="text" fullWidth nbInput formControlName="businesses">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="heatingSystem">Heizungsanlage</label>
                      <input id="heatingSystem" fullWidth type="text" nbInput formControlName="heatingSystem">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="playGround">Spielplatz</label>
                      <input id="playGround" fullWidth type="text" nbInput formControlName="playGround">
                    </div>

                    <div class="col-6 md:col-4">
                      <label class="label" for="floorCountWithoutCellar">Stockwerke</label>
                      <input id="floorCountWithoutCellar" type="text" nbInput fullWidth
                             formControlName="floorCountWithoutCellar">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="entryCount">Anzahl Eingänge</label>
                      <input id="entryCount" min="0" step="1" type="number" nbInput fullWidth
                             formControlName="entryCount">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="garageCount">Garagen</label>
                      <input id="garageCount" min="0" step="1" type="number" nbInput fullWidth
                             formControlName="garageCount">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="undergroundGarageCount">Tiefgaragen</label>
                      <input id="undergroundGarageCount" min="0" step="1" type="number" nbInput
                             fullWidth formControlName="undergroundGarageCount">
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="area">Wohnfläche</label>
                      <input id="area" min="0" type="number" nbInput formControlName="area" fullWidth>
                    </div>
                    <div class="col-6 md:col-4">
                      <label class="label" for="convertedAttic">Ausgebautes Dachgeschoß</label>
                      <wr-building-attic-select id="convertedAttic"
                                                formControlName="convertedAttic"></wr-building-attic-select>
                    </div>
                    <div class="col-6 md:col-4 coordinates">
                      <label for="coordinate-button" class="label">Koordinaten</label>
                      <div class="col-6 coordinate-buttons">
                        <button id="coordinate-button" [nbTooltip]="coordinateTooltip()"
                                nbTooltipStatus="primary"
                                class="coordinate-button" size="medium" [disabled]="!isEditing" nbButton
                                status="primary" (click)="coordinateDialog()">
                          <nb-icon pack="eva" icon="globe-outline"></nb-icon>
                          Koordinaten
                        </button>
                        <button *ngIf="isAdmin()" size="medium" [disabled]="!isEditing || !showResetCoordinates"
                                nbButton status="primary"
                                (click)="resetCoordinates()">
                          <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>

                  </div>
                  <div class="col-12">
                    <label class="label" for="note">Bemerkungen</label>
                    <textarea id="note" nbInput fullWidth formControlName="note"></textarea>
                  </div>
                </ng-container>
              </div>
            </form>
          </nb-tab>
          <nb-tab *ngIf="buildingSections" [tabTitle]="'Gebäude Teile (' + buildingSectionCount() + ')'">
            <form [formGroup]="form">
              <div class="building-section">
                <div class="building-section-header" *ngIf="isAdmin()">
                  <div class="info-text">
                    <nb-icon pack="eva" icon="alert-circle-outline"></nb-icon>
                    Jeder Gebäudeteil kann mehrere Adressen haben. Jede Straße kann mehrere Hausnummern haben.
                  </div>
                  <ng-container *ngIf="isEditing">
                    <button nbButton
                            ghost
                            status="primary"
                            *ngIf="!isMergingBuildingSections"
                            (click)="isMergingBuildingSections=true"
                            nbTooltip="Gebäudeteile zusammenführen">
                      <nb-icon pack="eva" icon="layers-outline"></nb-icon>
                    </button>
                    <button nbButton
                            status="success"
                            nbTooltip="Zusammenführen"
                            nbTooltipStatus="success"
                            *ngIf="isMergingBuildingSections"
                            [disabled]="!canMergeBuildingSections()"
                            (click)="mergeBuildingSections()">
                      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
                    </button>
                    <button nbButton
                            ghost
                            *ngIf="isMergingBuildingSections"
                            (click)="isMergingBuildingSections=false"
                            nbTooltip="Abbrechen">
                      <nb-icon pack="eva" icon="close-outline"></nb-icon>
                    </button>
                    <button nbButton
                            ghost
                            status="primary"
                            *ngIf="isAdmin()"
                            (click)="addBuildingSection()">
                      <nb-icon pack="eva" icon="plus-outline"></nb-icon>
                    </button>
                  </ng-container>
                </div>
                <div class="building-section-list" *ngIf="buildingSections.length > 0" formArrayName="buildingSections">
                  <div *ngFor="let _ of buildingSections.controls; let i = index" [formGroupName]="i"
                       class="building-section-item-wrapper">
                    <div class="building-section-item">
                      <div class="building-section-row">
                        <div class="street-col">
                          <label class="label" for="street">Straße</label>
                          <input nbInput
                                 fullWidth
                                 id="street"
                                 type="text"
                                 formControlName="street"
                                 placeholder="Straße">
                          <div class="error" *ngIf="hasError('street')">Straße ist erforderlich!</div>
                        </div>
                      </div>
                      <div class="building-section-row">
                        <div class="number-col" formArrayName="houseNumbers">
                          <ng-container *ngFor="let _ of houseNumbers(i).controls; let j = index">
                            <div class="number-item" [formGroupName]="j">
                              <nb-form-field>
                                <input nbInput
                                       fullWidth
                                       type="text"
                                       placeholder="Nr"
                                       formControlName="number">
                                <button nbButton
                                        nbSuffix
                                        ghost
                                        *ngIf="isEditing && canDeleteHouseNumber(i, j)"
                                        (click)="deleteHouseNumber(i, j)">
                                  <nb-icon pack="eva" icon="close-outline"></nb-icon>
                                </button>
                              </nb-form-field>
                            </div>
                          </ng-container>
                          <button *ngIf="isEditing" (click)="addHouseNumber(i)" nbButton ghost status="primary">
                            <nb-icon pack="eva" icon="plus-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                      <wr-automatic-address
                        [formGroup]="$any(buildingSections.at(i).get('automaticAddress'))"></wr-automatic-address>
                    </div>
                    <div class="delete-merge-container" *ngIf="isAdmin() && isEditing">
                      <nb-checkbox formControlName="mergeSelected"
                                   *ngIf="isMergingBuildingSections"
                                   nbTooltip="Gebäudeteil zusammenführen"
                                   status="primary"></nb-checkbox>
                      <button nbButton
                              ghost
                              status="primary"
                              *ngIf="!isMergingBuildingSections"
                              [disabled]="!canDeleteBuildingSection(i)"
                              (click)="deleteBuildingSection(i)">
                        <nb-icon pack="eva" icon="trash-2-outline"></nb-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </nb-tab>
          <nb-tab tabTitle="Benachrichtigungen">
            <form [formGroup]="form">
              <div class="toggle-container">
                <label class="label" for="sendMaintenanceNotifications">Wartungstimer Erinnerungen</label>
                <nb-toggle formControlName="sendMaintenanceNotifications" id="sendMaintenanceNotifications">
                </nb-toggle>
              </div>
            </form>
          </nb-tab>
          <nb-tab tabTitle="Scans" *ngIf="buildingId">
            <wr-building-scan-list [buildingId]="buildingId"></wr-building-scan-list>
          </nb-tab>
        </nb-tabset>
      </ng-container>
    </nb-card-body>
    <nb-card-footer *ngIf="isAdmin()">
      <button size="medium" [disabled]="!isEditing" nbButton status="primary" (click)="submit()">Speichern</button>
      <button size="medium" [disabled]="!isEditing" nbButton status="basic" (click)="cancel()">Zurück</button>
    </nb-card-footer>
  </nb-card>
</div>
