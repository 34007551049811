import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import {
  AddressService,
  BuildingDetailDto,
  BuildingMergeDto,
  BuildingSectionService,
  BuildingSectionsMergeDto,
  BuildingService,
} from '@artemis-software/wr-api';
import { DeleteDialogComponent } from '@components/dialog/delete-dialog/delete-dialog.component';
import { BuildingSectionDetailDto } from '@artemis-software/wr-api/model/buildingSectionDetailDto';
import { PendingChanges } from '@guards/pending-changes.guard';
import { UniqueInternalNumberValidator } from '@/utils/validators';
import {
  CoordinateDialogComponent,
  zeroCoordinates,
} from '@components/dialog/coordinate-dialog/coordinate-dialog.component';
import { AddressDialogComponent, houseNumber } from '@components/dialog/address-dialog/address-dialog.component';
import { isAdmin } from '@/utils/admin-utils';
import {
  PropertyManagerDialogComponent,
} from '@components/dialog/property-manager-dialog/property-manager-dialog.component';
import { BuildingScanListComponent } from '@components/building-scan-list/building-scan-list.component';

@Component({
  selector: 'wr-building-detail-page',
  templateUrl: './building-detail-page.component.html',
  styleUrls: ['./building-detail-page.component.scss'],
})

export class BuildingDetailPageComponent implements PendingChanges {

  @ViewChild(BuildingScanListComponent)
  scanList?: BuildingScanListComponent;

  isAdmin = isAdmin();

  isEditing = false;
  firstSubmit = false;
  form!: FormGroup;
  showResetCoordinates = false;
  showCoordinateTooltip = false;
  isMergingBuildingSections = false;

  saving$ = new BehaviorSubject(false);

  constructor(readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly buildingService: BuildingService,
              private readonly addressService: AddressService,
              private readonly dialogService: NbDialogService,
              private readonly formBuilder: FormBuilder,
              private readonly toastrService: NbToastrService,
              private readonly buildingSectionService: BuildingSectionService) {
    route.paramMap.subscribe(async (params) => {
      const id = this.buildingId = params.get('id');
      if (id) {
        if (id === 'new') {
          this.isEditing = true;
          await this.initForm();
        } else {
          const building = await firstValueFrom(this.buildingService.findById(id));
          await this.initForm(building);
          this.form.disable();
        }
      } else {
        this.router.navigate(['buildings']);
      }

    });
  }

  buildingId: string | null = null;

  async initForm(dto?: BuildingDetailDto): Promise<void> {
    this.showCoordinateTooltip = dto?.coordinates != null;
    const idControl = new FormControl(dto?.id);
    const internalNumberControl = new FormControl(dto?.internalNumber, [Validators.required],
      [UniqueInternalNumberValidator.createValidator(this.buildingService, idControl)]);
    this.form = new FormGroup({
      id: idControl,
      name: new FormControl(dto?.name),
      buildingClass: new FormControl(dto?.buildingClass, [Validators.required]),
      internalNumber: internalNumberControl,
      externalNumber: new FormControl(dto?.externalNumber),
      organisationId: new FormControl(dto?.organisation?.id, [Validators.required]),
      area: new FormControl(dto?.area),
      floorCountWithoutCellar: new FormControl(dto?.floorCountWithoutCellar),
      entryCount: new FormControl(dto?.entryCount),
      convertedAttic: new FormControl(dto?.convertedAttic),
      garageCount: new FormControl(dto?.garageCount),
      undergroundGarageCount: new FormControl(dto?.undergroundGarageCount),
      owner: new FormControl(dto?.owner),
      propertyManager: new FormControl(dto?.propertyManager),
      propertyCaretaker: new FormControl(dto?.propertyCaretaker),
      assistant: new FormControl(dto?.assistant),
      businesses: new FormControl(dto?.businesses),
      propertyKey: new FormControl(dto?.propertyKey),
      keySafeWithPosition: new FormControl(dto?.keySafeWithPosition),
      codes: new FormControl(dto?.codes),
      heatingSystem: new FormControl(dto?.heatingSystem),
      playGround: new FormControl(dto?.playGround),
      buildingSections: this.formBuilder.array([]),
      buildingType: new FormControl(dto?.buildingType),
      otherBuildingType: new FormControl(dto?.otherBuildingType),
      otherBuildingClass: new FormControl(dto?.otherBuildingClass),
      coordinates: new FormControl(dto?.coordinates ?? null),
      note: new FormControl(dto?.note),
      embeddedPropertyManager: new FormControl(dto?.embeddedPropertyManager),
      propertyManagerId: new FormControl(''),
      sendMaintenanceNotifications: new FormControl(dto?.sendMaintenanceNotifications),
    });

    this.form.get('buildingClass')?.valueChanges.subscribe(value => {
      if (value != BuildingDetailDto.BuildingClassEnum.Other) {
        this.form.get('otherBuildingClass')?.setValue(null);
      }
    });

    this.form.get('buildingType')?.valueChanges.subscribe(value => {
      if (value != BuildingDetailDto.BuildingTypeEnum.Other) {
        this.form.get('otherBuildingType')?.setValue(null);
      }
    });

    if (dto && dto.sections.length > 0) {
      const buildingSections = await firstValueFrom(this.buildingSectionService.findAllByBuildingId(dto?.id ?? ''));

      // group building sections by street, plz and city
      const buildingSectionGroups = buildingSections.reduce((acc, section) => {
        const key = `${section.address.street} ${section.address.zipCode}`;
        acc[key] = acc[key] || [];
        acc[key].push(section);
        return acc;
      }, {} as { [key: string]: BuildingSectionDetailDto[] });

      // create form groups for each group
      Object.keys(buildingSectionGroups).forEach(key => {
        const houseNumbers = [];
        for (const section of buildingSectionGroups[key]) {
          const number = section.address?.number;
          houseNumbers.push(new FormGroup({
            number: new FormControl(number),
            addressId: new FormControl(section.address?.id),
            sectionId: new FormControl(section.id),
          }));
        }

        const address = buildingSectionGroups[key][0].address;
        this.buildingSections.push(new FormGroup({
          street: new FormControl(address?.street),
          houseNumbers: new FormArray(houseNumbers),
          mergeSelected: new FormControl(false),
          automaticAddress: new FormGroup({
            zipCode: new FormControl(address?.zipCode, [Validators.required]),
            city: new FormControl(address?.city, [Validators.required]),
            province: new FormControl(address?.state),
          }),
          country: new FormControl(address?.country),
        }));
      });
    }
  }

  displayOtherBuildingClass(): boolean {
    return this.form?.get('buildingClass')?.value === BuildingDetailDto.BuildingClassEnum.Other;
  }

  displayOtherBuildingType(): boolean {
    return this.form?.get('buildingType')?.value === BuildingDetailDto.BuildingTypeEnum.Other;
  }

  get buildingSections(): FormArray {
    return this.form.get('buildingSections') as FormArray;
  }

  houseNumbers(index: number): FormArray {
    return this.buildingSections.at(index).get('houseNumbers') as FormArray;
  }

  hasError(controlName: string): boolean {
    const control = this.form?.get(controlName);
    return this.firstSubmit && !!control?.errors;
  }

  onEdit(): void {
    this.isEditing = true;
    this.form?.enable();
    this.form?.get('country')?.disable();
  }

  async cancel(): Promise<void> {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['building', this.form?.value.id]);
    });
  }

  async submit(): Promise<void> {
    this.firstSubmit = true;
    this.form?.markAsTouched();
    this.form.updateValueAndValidity();

    if (!this.form?.valid) {
      this.toastrService.danger('Gebäude kann nicht gespeichert werden, es gibt noch Fehler.', 'Gebäude');
      return;
    }

    if (this.buildingSections.length <= 0) {
      this.toastrService.danger('Gebäude muss mindestens einen Gebäudeteil haben.', 'Gebäude');
      return;
    }

    try {
      this.saving$.next(true);
      const buildingSections = this.buildingSections.value;

      for (const buildingSection of buildingSections) {
        if (buildingSection.street == null || buildingSection.street == '') {
          this.toastrService.danger('Straße darf nicht leer sein.', 'Gebäude');
          return;
        }

        // check if street and zip code are unique
        if (buildingSections.filter((b: any) => b.street == buildingSection.street && b.zipCode == buildingSection.zipCode).length > 1) {
          this.toastrService.danger('Straße und PLZ müssen eindeutig sein.', 'Gebäude');
          return;
        }
      }


      for (let i = 0; i < this.buildingSections.length; i++) {
        const houseNumbers = this.houseNumbers(i).value;
        if (houseNumbers.length <= 0) {
          this.toastrService.danger('Gebäudeteil benötigt mindestens eine Hausnummer.', 'Gebäude');
          return;
        }

        for (const houseNumber of houseNumbers) {
          if (houseNumber.number == null || houseNumber.number == '') {
            this.toastrService.danger('Hausnummer darf nicht leer sein.', 'Gebäude');
            return;
          }
        }

        for (const houseNumber of houseNumbers) {
          if (houseNumbers.filter((h: any) => h.number == houseNumber.number).length > 1) {
            this.toastrService.danger('Hausnummer darf nicht doppelt sein.', 'Gebäude');
            return;
          }
        }
      }

      const buildingMergeDto: BuildingMergeDto = {
        ...this.form?.value,
        coordinatesReference: { value: this.form?.value.coordinates },
      };

      const updatedBuilding = await firstValueFrom(this.buildingService.merge(buildingMergeDto));

      const addressSectionMergePromises = this.buildingSections.value.map((section: any, i: number) => {
        const houseNumbers = this.houseNumbers(i);
        return houseNumbers.value.map(async (houseNumber: any) => {
          const updatedAddress = await firstValueFrom(this.addressService.merge({
            id: houseNumber.addressId,
            street: section.street,
            number: houseNumber.number,
            zipCode: section.automaticAddress.zipCode,
            city: section.automaticAddress.city,
            state: section.automaticAddress.province,
            country: section.country,
          }));
          return firstValueFrom(this.buildingSectionService.merge({
            id: houseNumber.sectionId,
            buildingId: updatedBuilding.id,
            addressId: updatedAddress.id,
          }));
        });
      }).flatMap((promises: Promise<any>[]) => promises);
      await Promise.all(addressSectionMergePromises);

      this.isEditing = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['building', updatedBuilding.id]).then(() => {
          this.toastrService.success('Gebäude wurde erfolgreich gespeichert', 'Gebäude');
        });
      });
    } catch (e) {
      console.error(e);
      this.toastrService.danger('Gebäude konnte nicht gespeichert werden.', 'Fehler');
    } finally {
      this.saving$.next(false);
    }
  }

  async onDelete(): Promise<void> {
    const result = await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Gebäude löschen',
        message: 'Bist du sicher, dass du dieses Gebäude löschen willst?',
      },
    }).onClose);
    if (result) {
      await firstValueFrom(this.buildingService.deleteById(this.form?.value.id));
      this.router.navigate(['buildings']);
      this.toastrService.success('Gebäude wurde erfolgreich gelöscht', 'Gebäude');
    }
  }

  async navigateBack(): Promise<void> {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      await this.router.navigate(['buildings']);
    }
  }

  addBuildingSection() {
    const houseNumbers = new FormArray([
      new FormGroup({
        sectionId: new FormControl(''),
        addressId: new FormControl(''),
        number: new FormControl(''),
      }),
    ]);
    this.buildingSections.insert(0, this.formBuilder.group({
      addressId: new FormControl(''),
      street: new FormControl(''),
      houseNumbers: houseNumbers,
      mergeSelected: new FormControl(false),
      automaticAddress: new FormGroup({
        zipCode: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        province: new FormControl(''),
      }),
      country: new FormControl('AT'),
    }));
  }

  canDeleteBuildingSection(i: number): boolean {
    return this.isEditing && !this.houseNumbers(i).value.some((houseNumber: any) => !!houseNumber.sectionId);
  }

  async deleteBuildingSection(i: number) {
    const section = this.buildingSections.value[i];
    const street = section.street;
    const zipCode = section.zipCode;

    if (await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Gebäudeteil löschen',
        message: 'Bist du sicher, dass du ALLE Gebäudeteile in der *' + street + ' ' + zipCode + '* löschen willst?',
      },
    }).onClose)) {
      this.buildingSections.removeAt(i);
    }
  }

  addHouseNumber(index: number) {
    this.houseNumbers(index).push(this.formBuilder.group({
      sectionId: new FormControl(undefined),
      addressId: new FormControl(undefined),
      number: new FormControl(undefined, [Validators.required]),
    }));
  }

  canDeleteHouseNumber(i: number, j: number): boolean {
    const section = this.buildingSections.value[i];
    const address = section.houseNumbers[j];
    return this.isEditing && !address?.sectionId;
  }

  async deleteHouseNumber(i: number, j: number) {
    const address = this.buildingSections.value[i];
    const zipCode = address.zipCode;
    const street = address.street;
    const houseNumber = address.houseNumbers[j];

    if (!houseNumber?.number || await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Hausnummer löschen',
        message: `Bist du sicher, dass du die Hausnummer *${street} ${houseNumber.number} ${zipCode}* löschen willst?`,
      },
    }).onClose)) {
      this.houseNumbers(i).removeAt(j);
    }
  }

  buildingSectionCount() {
    let sum = 0;
    for (const section of this.buildingSections.value) {
      sum += section.houseNumbers.length;
    }
    return sum;
  }

  hasPendingChanges(): boolean | Promise<boolean> {
    return this.isEditing || (this.scanList?.hasPendingChanges() ?? false);
  }

  async coordinateDialog() {
    const dialogRef = this.dialogService.open(CoordinateDialogComponent, {
      context: {
        title: 'Koordinaten',
        coordinates: this.form?.get('coordinates')?.value ?? zeroCoordinates(),
      },
    });
    const manualCoordinates = await firstValueFrom(dialogRef.onClose);
    if (manualCoordinates) {
      this.form?.get('coordinates')?.setValue(manualCoordinates);
      this.showCoordinateTooltip = true;
    }
  }

  coordinateTooltip() {
    const coordinates = this.form?.get('coordinates')?.value;
    if (coordinates != null) {
      this.showResetCoordinates = true;
      return `${coordinates.latitude}, ${coordinates.longitude}`;
    } else {
      this.showResetCoordinates = false;
      return 'Keine Koordinaten gesetzt';
    }
  }

  async resetCoordinates() {
    const result = await firstValueFrom(this.dialogService.open(DeleteDialogComponent, {
      context: {
        title: 'Koordinaten zurücksetzen',
        message: 'Bist du sicher, dass du die Koordinaten zurücksetzen willst?',
      },
    }).onClose);
    if (result) {
      this.form?.get('coordinates')?.setValue(null);
      this.showCoordinateTooltip = false;
    }
  }

  async mergeBuildingSections() {
    const selectedSections = this.buildingSections.value.filter((section: any) => section.mergeSelected);

    if (selectedSections.length < 2) {
      this.toastrService.danger('Mindestens zwei Gebäudeteile müssen ausgewählt sein.', 'Gebäude');
      return;
    }

    let street = '';
    selectedSections.forEach((section: any, i: number) => {
      street += i === selectedSections.length - 1 ? section.street : section.street + ', ';
    });

    const houseNumbers: houseNumber[] = [];
    selectedSections.forEach((section: any) => {
      houseNumbers.push(...section.houseNumbers);
    });

    const dialogRef = this.dialogService.open(AddressDialogComponent, {
      context: {
        title: 'Adresse',
        street: street,
        address: selectedSections[0].automaticAddress,
        houseNumbers: houseNumbers,
      },
    });

    const mergedBuildingSection = await firstValueFrom(dialogRef.onClose);

    if (mergedBuildingSection) {
      try {
        this.saving$.next(true);
        const sectionIds = selectedSections.map((section: any) => section.houseNumbers.map((houseNumber: any) => houseNumber.sectionId)).flat();

        const houseNumbers = mergedBuildingSection.houseNumbers;

        const addressSectionMergePromises = async () => {
          const newAddress = await firstValueFrom(this.addressService.merge({
            street: mergedBuildingSection.street,
            number: houseNumbers.number,
            zipCode: mergedBuildingSection.automaticAddress.zipCode,
            city: mergedBuildingSection.automaticAddress.city,
            state: mergedBuildingSection.automaticAddress.province,
            country: 'AT',
          }));
          const buildingSectionsMergeDto: BuildingSectionsMergeDto = {
            ids: sectionIds,
            buildingId: this.form?.value.id,
            addressId: newAddress.id,
          };
          await firstValueFrom(this.buildingSectionService.mergeBuildingSections(buildingSectionsMergeDto));
        };
        await addressSectionMergePromises();

        this.isEditing = false;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['building', this.form?.value.id]).then(() => {
            this.toastrService.success('Gebäudeteile wurden erfolgreich zusammengefügt.', 'Gebäude');
          });
        });
      } catch (e) {
        console.error(e);
        this.toastrService.danger('Gebäudeteile konnten nicht zusammengeführt werden.', 'Fehler');
        return;
      } finally {
        this.saving$.next(false);
      }
    }
  }

  canMergeBuildingSections(): boolean {
    return this.isEditing && this.buildingSections.value.filter((section: any) => section.mergeSelected).length > 1;
  }

  openPropertyManagerDialog() {
    this.dialogService.open(PropertyManagerDialogComponent, {
      context: {
        propertyManager: this.form.get('propertyManager')?.value || this.form.get('embeddedPropertyManager')?.value,
      },
    })
      .onClose.subscribe(result => {
        if (result?.propertyManager) {
          this.form.get('propertyManager')?.setValue(result.propertyManager);
          this.form.get('propertyManagerId')?.setValue(result.propertyManager.id);
          this.form.get('embeddedPropertyManager')?.setValue(null);
        } else if (result?.embeddedPropertyManager) {
          this.form.get('embeddedPropertyManager')?.setValue(result.embeddedPropertyManager);
          this.form.get('propertyManager')?.setValue(null);
        }
      });
  }

  getPropertyManagerName(): string {
    const propertyManager = this.form.get('propertyManager')?.value;
    if (propertyManager) {
      return `${propertyManager.firstName} ${propertyManager.lastName}`;
    } else if (this.form.get('embeddedPropertyManager')?.value) {
      const embeddedPropertyManager = this.form.get('embeddedPropertyManager')?.value;
      return `${embeddedPropertyManager.firstName} ${embeddedPropertyManager.lastName}`;
    }
    return '';
  }
}
