import { Component, computed, inject, signal } from '@angular/core';
import { BuildingEquipmentTreeViewDto } from '@artemis-software/wr-api';
import { ScanViewerService } from '@pages/building-scan-detail-page/scan-viewer-service';

@Component({
  selector: 'wr-building-scan-equipment-tree-view',
  templateUrl: './building-scan-equipment-tree-view.component.html',
  styleUrls: ['./building-scan-equipment-tree-view.component.scss'],
})
export class BuildingScanEquipmentTreeViewComponent {
  readonly trackByFn = (index: number, item: BuildingEquipmentTreeViewDto) => item.id;

  readonly filterText = signal('');

  private readonly scanViewerService = inject(ScanViewerService);

  readonly filteredBuildingEquipments = computed(() => {
    const filter = this.filterText().toLowerCase();
    const allBuildingEquipments = this.scanViewerService.buildingEquipments();
    return allBuildingEquipments.filter(buildingEquipment =>
      buildingEquipment.equipmentName.toLowerCase().includes(filter)
      || buildingEquipment.sectionText.toLowerCase().includes(filter)
      || buildingEquipment.maintenanceTimers.some(maintenanceTimer => maintenanceTimer.associatedPointOfInterest?.name.toLowerCase().includes(filter)),
    );
  });

  get loading() {
    return this.scanViewerService.buildingEquipmentsLoading();
  }

}
